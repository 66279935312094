import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import rehypeStringify from 'rehype-stringify'
import remarkGfm from 'remark-gfm'
import stringWidth from 'string-width'
import remarkParse from 'remark-parse'
import remarkBreaks from 'remark-breaks'
import remarkMath from 'remark-math'
import PropTypes from 'prop-types'
import {Divider, Space, Timeline, Typography} from "antd";
import {Link} from 'wouter'
import Todo from "./Todo";
import OauthConnectButton from "./OauthConnectButton";
import querystring from "query-string";
import VoiceCallButton from "./VoiceCallButton";
import UserContentPreview from "./UserContentPreview";
import ExternalLink from "./ExternalLink";
import ContactSelect from "./ContactSelect";
import ContactCard from "./ContactCard";
import Toggle from "./Toggle";
import DraftEditorManager from "./DraftEditorManager";
import rehypeHighlight from 'rehype-highlight'
import 'highlight.js/styles/atom-one-dark.css'
import 'katex/dist/katex.min.css';
import ShortCodeMedia from "./ShortCodeMedia";

const table = `
| Month Number | Month Name |
|-----|-----|
| 1 | January |
| 2 | February |
| 3 | March |`;

MarkdownTimeline.propTypes = {
  markdown: PropTypes.string.isRequired,
  action: PropTypes.arrayOf(PropTypes.node),
  collapsible: PropTypes.object,
  components: PropTypes.object,
  style: PropTypes.object,
}

function MarkdownTimeline(props) {
  const remarkPlugins = [
    remarkBreaks,
    remarkParse,
    [remarkGfm, {stringLength: stringWidth}],
    remarkMath,
  ];

  const componentsOverride = {
    ul: (props) => {
      return <Timeline
        className="markdown-timeline-wrapper ul"
      >
        {props.children
        .filter((child) =>
          typeof child !== 'string')
        }
      </Timeline>
    },
    ol: (props) => {
      return <Space direction="vertical">
        <Timeline
          className="markdown-timeline-wrapper ol"
          mode="left"
        >
          {props.children
          .filter((child) =>
            typeof child !== 'string')
          .map((child) =>
            React
            .cloneElement(child, {timeline: true})
          )}
        </Timeline>
      </Space>
    },
    li: (props) => {
      return props.timeline ?
        <Timeline.Item
          className={props.className}
          color={props.active ? 'blue' : 'white'}
        >
          <Todo
            label="Step"
            type="step"
          >
            {props.children}
          </Todo>
        </Timeline.Item> :
        <li className={`font-lg ${props.className}`} style={{paddingBottom: 8}}>{props.children}</li>
        // <Timeline.Item
        //   className={props.className}
        //   color={props.active ? 'blue' : 'gray'}
        // >
        //   <Todo
        //     label="Info"
        //     type="info"
        //   >
        //     {props.children}
        //   </Todo>
        // </Timeline.Item>
    },
    a: (props) => {
      switch (true) {
        case props.href
        .startsWith("mailto"):
          return <a
            className="ai-text underline"
            href={props.href}
          >
            {props.children}
          </a>
        case props.href
        .startsWith("/"):
          return <Link
            to={props.href}
          >
            {props.children}
        </Link>
        case props.href
        .startsWith("#"): {
          const [
            componentName,
            queryParams,
          ] = props.href.split("?");

          const componentProps =
            querystring
            .parse(
              queryParams, {
                parseBooleans: true,
                parseNumbers: true,
              });

          switch(true) {
            case componentName === "#contact-card":
              return <ContactCard
                action={props.children}
                {...componentProps}
              />
            case componentName === "#contact-select":
              return <ContactSelect
                action={props.children}
                {...componentProps}
              />
            case componentName === "#voice-call":
              return <VoiceCallButton
                inline
                {...componentProps}
              >{props.children}
              </VoiceCallButton>
            case componentName === "#oauth":
              return <OauthConnectButton
                {...componentProps}>
                {props.children}
              </OauthConnectButton>
            case componentName === "#email-editor":
              return <div
                style={{
                  display: "flex",
                  width: "100%",
                  maxWidth: "700px",
                }}
                className="card-content-wrapper"
              >
                <DraftEditorManager
                  actionLabel={props.children}
                  action={componentProps.action}
                  {...componentProps}
                  style={{marginTop: '10px'}}
                />
              </div>
            case componentName === "#source-link":
              return <UserContentPreview
                {...componentProps}
                href={props.href}
              >
                {props.children}
              </UserContentPreview>
            default:
              return <ShortCodeMedia
                code={componentName.replace("#", "")}
              >
                {props.children}
              </ShortCodeMedia>
          }
        }
        default:
          return <ExternalLink
            className="external-link ai-text underline"
            href={props.href}
          >
            {props.children}
          </ExternalLink>
      }
    },
    code: (props) => {
      return <Typography.Text>
        {props.children}
      </Typography.Text>
    },
    hr: () => <Divider className="no-top small-bottom" />,
    ...(props.components || null),
  };

  console.log('MarkdownTimeline:render');

  return <div
    className="muted relative flex-col middle markdown-timeline-content font-lg"
    style={props.style || {minHeight: 50}}
  >
    {props.extra ?
      <div style={{height: 20}}>
        <div
          style={{
            width: 40,
            position: "absolute",
            top: -10,
            right: 0,
          }}>
          {props.extra}
        </div>
      </div> :
      null
    }
    {!!props.collapsible ?
      <Toggle
        defaultExpanded={!props.collapsible?.rows}
        rows={props.collapsible?.rows}
        trigger={<label>Expand</label>}
      >
        <ReactMarkdown
          children={props.markdown}
          remarkPlugins={remarkPlugins}
          rehypePlugins={[
            [rehypeHighlight, {fragment: true}],
            rehypeStringify,
            rehypeKatex,
          ]}
          components={componentsOverride}
        />
      </Toggle> :
      <ReactMarkdown
        children={props.markdown}
        remarkPlugins={remarkPlugins}
        rehypePlugins={[
          [rehypeHighlight, {fragment: true}],
          rehypeStringify,
          rehypeKatex,
        ]}
        components={componentsOverride}
      />
    }
    {!!props.action &&
      !!props.action?.length &&
      <div className="space space-center-y">
        {props.action}
      </div>}
  </div>;
}

const MemoizedComponent = React
.memo(
  MarkdownTimeline,
  (prevProps, nextProps) => {
    return prevProps.markdown === nextProps.markdown;
  });

export default MemoizedComponent;
