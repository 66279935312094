import React from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, List, Tag, Typography} from "antd";
import UserAvatar from "./UserAvatar";
import {DownArrowOutlined, FlagIcon} from "@web/assets/icons";
import now from "lodash/now";
import Day from "./Day";
import MarkdownTimeline from "./MarkdownTimeline";
import Toggle from "./Toggle";
import StatusActions from "./StatusActions";
import {useDispatch, useSelector} from "react-redux";
import IconTip from "./IconTip";
import contentActions from "@web/redux/content/actions";
import ContactDetailsListItemMeta from "./ContactDetailsListItemMeta";
import DetailData from "@web/ui/containers/DetailData";
import ContentDetailWrapper from "./ContentDetailWrapper";
import DetailTimelineDay from "./timeline/DetailTimelineDay";
import dayjs from "dayjs";

ContactDetailsListItem.propTypes = {
  path: PropTypes.string.isRequired,
  activityPath: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactIcon: PropTypes.node,
};

function ContactDetailsListItem(props) {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = React
  .useState(false);

  const eventsByActivity =
    useSelector((state) => {
      return state.Content.activity?.[props.path]?.eventsByActivity?.[props.activityPath];
    });

  const event =
    useSelector((state) =>
      state.Content.events[eventsByActivity[0]]);

  const onClickItem = () => {
    !showDetails &&
    dispatch(contentActions
    .loadContext(event.itemPath));
    setShowDetails(!showDetails);
  }

  return <List.Item
    key={`list-item-${props.activityPath}`}
    actions={[
      <IconTip
        key="details"
        title="Details"
        placement="right"
      >
        <Button
          ghost
          onClick={onClickItem}
        >
          <DownArrowOutlined
            key="details"
            className="green-icon"
          />
        </Button>
      </IconTip>,
    ]}
  >
    <div className="space space-col" style={{width: '100%'}}>
      {eventsByActivity
      .map((eventId) => {
        return <ContactDetailsListItemMeta
            key={eventId}
            path={props.path}
            activityPath={props.activityPath}
            eventId={eventId}
            displayName={props.displayName}
            name={props.name}
            email={props.email}
            contactIcon={props.contactIcon}
          />
      })
      }
      {showDetails &&
        <DetailTimelineDay
          path={event.itemPath}
          dateKey={dayjs(event.date).format("YYYY-MM-DD")}
          collapsed={false}
        />
      }
    </div>
  </List.Item>
}

export default ContactDetailsListItem;
