import React from 'react';
import PropTypes from 'prop-types';
import Toggle from "./Toggle";
import StatusActions from "./StatusActions";
import {api} from "@web/lib/api";
import {useDispatch, useSelector} from "react-redux";
import {Tag, Typography} from "antd";
import aiActions from "@web/redux/ai/actions";

RecommendedTodo.propTypes = {
  eventId: PropTypes.string.isRequired,
  activityPath: PropTypes.string.isRequired,
  collapsed: PropTypes.bool,
};

function RecommendedTodo(props) {
  const dispatch = useDispatch();
  const event =
    useSelector((state) =>
      state.Content.events[props.eventId]);

  const [userTaskPath, setUserTaskPath] = React
  .useState(event.userTaskPath);

  const activeUserTaskPath =
    useSelector((state) =>
      state.AI.userTask?.path);

  if (!event) {
    return null;
  }

  if (!event?.recommendedTodo) {
    return null;
  }

  const setActiveTask = () => {
    if (userTaskPath) {
      console.log("setActiveTask:activityPath:", props.activityPath);
      dispatch(aiActions.setActiveUserTask(userTaskPath));
      return;
    }
    api.call("user-createTask", {
      activityPath: props.activityPath,
      eventId: props.eventId,
    })
    .then((response) => {
      console.log("user-createTask:response:", response);
      dispatch(aiActions.setActiveUserTask(response.data.path));
      setUserTaskPath(response.data.path);
    });
  }

  return <Toggle
      key="recommended-todo"
      trigger={<span className="fat-chip font-sm uppercase">Todo</span>}
      defaultExpanded={!props.collapsed}
    >
      <div
        className="space space-col gap-small"
        style={{paddingLeft: '22px', borderLeft: 'solid 4px rgba(255,255,255,.1)', marginBottom: '16px'}}
        onClick={setActiveTask}
      >
        {/*<label className="muted font-xl">{props.contentItemActivity.recommendedTodo.dueDateOfTodo && "By " + dayjs(props.contentItemActivity.recommendedTodo.dueDateOfTodo).format("dddd MMMM D h:mm a")}</label>*/}
        <label className="muted font-lg">
          {event.recommendedTodo.goal}
        </label>
        <Typography.Text
          className="ai-text">
          {event.recommendedTodo.summary}
        </Typography.Text>
        <h6>
          <div className="space space-center-y space-between">
            <StatusActions
              path={props.activityPath}
              initialStatus={event.recommendedTodo.status}
              iconClassName="green-icon"
              actions={['done', 'dismiss']}
            />
            {!!userTaskPath &&
              activeUserTaskPath === userTaskPath &&
              <Tag color="tan">active</Tag>
            }
          </div>
        </h6>
      </div>
    </Toggle> || null
}

export default RecommendedTodo;
