import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Tag} from "antd";
import {DownArrowOutlined, PaymentIcon} from "@web/assets/icons";
import MarkdownTimeline from "./MarkdownTimeline";
import dayjs from "dayjs";
import {useSelector} from "react-redux";
import Day from "./Day";

TimelinePaymentsCard.propTypes = {
  path: PropTypes.string.isRequired,
  date: PropTypes.number,
  collapse: PropTypes.bool,
};

function TimelinePaymentsCard(props) {
  const [collapsed, setCollapsed] =
    React.useState(props.collapse);

  const payment =
    useSelector((state) => {
      return state.Content.data?.[props.path];
    });

  if (!payment) {
    return null;
  }

  const onClickCollapse = () => {
    setCollapsed(!collapsed);
  }

  const amount =
    payment.card?.amount ||
    +payment.card?.subtitle?.replaceAll(/[$,]/g, "")?.trim();

  const subtitle =
    isFinite(amount) ?
      `$${amount}` :
      payment.card.subtitle;

  return <Card
    style={{width: '100%'}}
    bordered={false}
    extra={<Button ghost onClick={onClickCollapse}><DownArrowOutlined /></Button>}
    title={<div className="space space-center-y">
      <PaymentIcon/>&nbsp;
      <label className="ai-text font-large card-header-title-text">
        {payment.card?.title} {subtitle}
      </label>&nbsp;
      <label className="font-large card-header-title-text">
        <Day ts={payment.date || props.date} format="dddd MMMM D[,] h:mm a"/>
      </label>
    </div>}
    className="card-rounded card-simple extra-round extra-padding card-body-scroll"
  >
    {/*<h6 className="ai-text">{payment.card?.subtitle} {payment.card?.title}</h6>*/}
    {!collapsed &&
      <div className="space space-col" style={{height: '80%'}}>
        <MarkdownTimeline
          markdown={payment.summary}
        />
    </div>}
    <div className="space space-center-y">
      {payment.card?.labels?.map((label) =>
      <Tag key={label} className="muted">{label}</Tag>)}
    </div>
  </Card>
}

export default TimelinePaymentsCard;
