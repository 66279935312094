import React, {useContext, useEffect} from 'react';
import PropTypes from "prop-types";
import {AppContext} from '@web/hooks/context'
// import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import {useDispatch, useSelector} from "react-redux";
import contentActions from "@web/redux/content/actions";
import appActions from "@web/redux/app/actions";

ContactDetailData.propTypes = {
  path: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function ContactDetailData({path, children, ...props}) {
  console.log("ContactDetailData:", path, props);
  const dispatch = useDispatch();
  const [, user] = useContext(AppContext);

  const clearActiveDetail =
    React.useCallback(() => {
      dispatch(appActions.clearActiveDetail());
    }, []);

  const loadAllContent = React
  .useCallback(() => {
    dispatch(contentActions.loadAllContent({path}));
  }, [path]);

  const contact =
    useSelector((state) => {
      return state.Content?.data?.[path];
    });

  const activityList =
    useSelector((state) =>
      state.Content.activity?.[path]?.activityList);

  useEffect(() => {
    dispatch(contentActions.loadContent({path}));
  }, [path]);

  if (!user?.uid) {
    return null;
  }

  if (!path) {
    return null;
  }

  return React
  .cloneElement(
    React.Children
    .only(children), {
      ...props,
      path,
      activityList,
      contact: contact || props.contact,
      onClose: clearActiveDetail,
      loadAllContent,
    });
}

export default ContactDetailData;
