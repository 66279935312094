import React from 'react';
import PropTypes from 'prop-types';
import {Card, Divider, Empty, List, Typography} from "antd";
import {PlusIcon} from "@web/assets/icons";
import TimelineEmailWrapper from "../TimelineEmailWrapper";
import TimelinePaymentsCard from "../TimelinePaymentsCard";
import TimelineDeliveriesCard from "../TimelineDeliveriesCard";
import TimelineCalendarsCard from "../TimelineCalendarsCard";
import TimelineFilesCard from "../TimelineFilesCard";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import Toggle from "../Toggle";
import Day from "../Day";

DetailTimelineDay.propTypes = {
  path: PropTypes.string,
  dateKey: PropTypes.string,
  collapsed: PropTypes.bool,
};

function DetailTimelineDay(props) {
  /** @type {web.client.Content} */
  const content =
    useSelector((state) => {
    return state.Content?.activity?.[props.path]?.timeline?.[props.dateKey];
  });

  const item = useSelector((state) => {
    return state.Content?.data?.[props.path];
  });

  const activeServiceId =
    useSelector((state) => {
      return state.App.activeDetailServiceId;
    });

  if (!content) {
    return null;
  }

  return <div className="space space-col">
    <List
      dataSource={Object.entries(content || {})}
      renderItem={([serviceId, items], index, list) => {
        switch (serviceId) {
          case "messages":
          case "threads": {
            return <List.Item key={serviceId}>
              <div className="space space-col">
                <Toggle
                  defaultExpanded={!props.collapsed}
                  trigger={
                    <Divider
                      className="small-top small-bottom"
                      orientation="left"
                    >
                      Emails&nbsp;&nbsp;
                      <span className="muted">
                        <Day ts={props.dateKey} format="MMM D" />
                      </span>
                    </Divider>
                  }
                >
                <TimelineEmailWrapper
                  key="messages-content"
                  items={items}
                  dateKey={props.dateKey}
                  contextPath={props.path}
                  cardTitle="Email"
                  enableActions={true}
                />
                </Toggle>
              </div>
            </List.Item>
          }
          case "files": {
            return <List.Item key={serviceId}>
              <div className="space space-col">
                <Toggle
                  defaultExpanded={!props.collapsed}
                  trigger={<Divider
                    classname="small-top small-bottom"
                    orientation="left"
                  >
                    Files&nbsp;&nbsp;
                    <span className="muted">
                    {dayjs(props.dateKey)
                    .format("MMM D")}
                  </span>
                  </Divider>}
                >
                <TimelineFilesCard
                  key="files-content"
                  files={items}
                />
                </Toggle>
              </div>
            </List.Item>
          }
          case "payments": {
          return <List.Item key={serviceId}>
            <div className="space space-col">
              <Toggle
                defaultExpanded={!props.collapsed}
                trigger={
                  <Divider className="small-top small-bottom" orientation="left">
                    Payments&nbsp;&nbsp;
                    <span className="muted">
                    {dayjs(props.dateKey)
                    .format("MMM D")}
                  </span>
                  </Divider>
                }
              >
              <List
                title="Payments"
                dataSource={items || []}
                renderItem={
                  ({path, date}, index) => {
                    return <List.Item key={path}>
                      <TimelinePaymentsCard
                        path={path}
                        date={date}
                        collapse={index > 0 || activeServiceId !== "payments"}
                      />
                    </List.Item>
                  }}
                locale={{
                  emptyText: <div className="space space-center-y">
                    <PlusIcon/>
                    &nbsp;
                    <Typography.Text>
                      No payments
                    </Typography.Text>
                  </div>
                }}
              />
              </Toggle>
            </div>
          </List.Item>
          }
          case "deliveries": {
            return <List.Item key={serviceId}>
              <div className="space space-col">
                <Toggle
                  defaultExpanded={!props.collapsed}
                  trigger={
                    <Divider className="small-top small-bottom" orientation="left">
                      Deliveries&nbsp;&nbsp;
                      <span className="muted">
                        {dayjs(props.dateKey)
                        .format("MMM D")}
                      </span>
                    </Divider>
                  }
                >
                <List
                  title="Deliveries"
                  dataSource={items || []}
                  renderItem={
                    ({path, date}) => {
                      return <TimelineDeliveriesCard
                        key={path}
                        path={path}
                        date={date}
                        // payments={props.payments}
                      />
                    }}
                  locale={{
                    emptyText: <div className="space space-center-y">
                      <PlusIcon/>
                      &nbsp;
                      <Typography.Text>
                        No deliveries
                      </Typography.Text>
                    </div>
                  }}
                />
                </Toggle>
              </div>
            </List.Item>
          }
          case "calendars": {
            return <List.Item key={serviceId}>
              <div className="space space-col">
                <Toggle
                  defaultExpanded={!props.collapsed}
                  trigger={
                    <Divider className="small-top small-bottom" orientation="left">
                      Events&nbsp;&nbsp;
                      <span className="muted">
                    {dayjs(props.dateKey)
                    .format("MMM D")}
                  </span>
                    </Divider>
                  }
                >
                <List
                  title="Calendar"
                  dataSource={items || []}
                  renderItem={
                    ({path, date}) => {
                      return <TimelineCalendarsCard
                        key={path}
                        path={path}
                        date={date}
                      />
                    }}
                  locale={{
                    emptyText: <div className="space space-center-y">
                      <PlusIcon/>
                      &nbsp;
                      <Typography.Text>
                        No calendar events
                      </Typography.Text>
                    </div>
                  }}
                />
                </Toggle>
              </div>
            </List.Item>
          }
        }
      }}
      locale={{
        emptyText: <Empty
          description={null}
          image={null}
          imageStyle={{display: "none"}}
          style={{maxWidth: "600px"}}
        >
          <Card
            className="card-simple card-rounded card-no-header"
            bordered={false}
          >
            Nothing to display
          </Card>
        </Empty>
      }}
    />
  </div>
}

export default DetailTimelineDay;
