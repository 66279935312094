import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {AttachmentIcon, CalendarIcon, EmailIconOutlined, LayersIcon, PaymentIcon, TruckIcon} from "@web/assets/icons";

ActivityIconStrip.propTypes = {
  eventId: PropTypes.string.isRequired,
};

function ActivityIconStrip(props) {
  const event =
    useSelector((state) =>
      state.Content.events[props.eventId]);

  const item = useSelector((state) =>
    state.Content.data[event.itemPath]);

  // const eventsId = useSelector((state) => {
  //   return state.Content.activity?.[props.contactPath]?.eventsByActivity?.[props.activityPath]?.[0];
  // });
  // const event = useSelector((state) =>
  //   state.Content.events[eventsId]);

  if (!event) {
    return null;
  }

  return <div className="space space-center-y">
    {!!event.itemPath.includes("/messages/") &&
      <EmailIconOutlined
        size={19}
        className="muted"
      />
    }
    {!!event?.isMeetingRelated &&
      <CalendarIcon
        size={18}
        style={{position: 'relative', top: 0}}
        className="muted"/>
    }
    {!!item?.attachments &&
      <AttachmentIcon
        size={14}
        className="muted"
      />
    }
    {(!!event.itemPath.includes("/files/") || !!item?.filePaths) &&
      <LayersIcon
        size={17}
        className="muted"
      />}
    {event.isPaymentRelated === true &&
      <PaymentIcon
        size={18}
        className="muted"
      />
    }
    {event.isDeliveryRelated === true &&
      <TruckIcon
        size={18}
        className="muted"
        style={{marginTop: '2px'}}
      />
    }
  </div>
}

export default ActivityIconStrip;
