import React from 'react'
import {Space, Typography, List} from 'antd'
import Markdown from "./Markdown";
import FileCard from "./FileCard";
import TimeAgo from "@web/ui/components/TimeAgo";
import PropTypes from "prop-types";

MessagesListItem.propTypes = {
  message: PropTypes.object,
  messageId: PropTypes.string,
  path: PropTypes.string,
}

/**
 * @summary Handles a message or thread record from firestore
 * @param {object} message
 * @param {string} path
 * @return {Element}
 * @constructor
 */
function MessagesListItem ({message, path}) {
  const {text = "", snippet, from, to = []} = message.latest?.message || message;
  const displayText = text;
  return <List.Item key={path}>
    <Space direction="vertical" style={{width: "100%"}}>
      <div className="space space-center-y">
        <h6>{from?.name}</h6>
        <div className="space space-center-y">to {to?.map((contact) => (<u key={contact?.name || contact?.email}>{contact?.name || contact?.email}</u>))}</div>
        <TimeAgo date={message?.date} className="muted" />
      </div>
      <h6>{message.subject}</h6>
      {!!displayText && <Markdown markdown={displayText} active />}
      {message.hasAttachments && <FileCard size="large" media={{mimeType: 'application/pdf'}} showSummary />}
    </Space>
  </List.Item>
}
export default MessagesListItem;
