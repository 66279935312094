import React, {useCallback} from "react";
import {List} from "antd";
import {RightArrowOutlined} from "@web/assets/icons";
import {useDispatch, useSelector} from "react-redux"
import appActions from "@web/redux/app/actions";
import Day from "./Day";
import UserAvatar from "./UserAvatar";
import ExternalLink from "./ExternalLink";
import useDomainIcon from "@web/hooks/useDomainIcon";
import ListItemActive from "./ListItemActive";

function ContactsListItem(props) {
  const dispatch =
    useDispatch();

  const isMobile = true;
  // console.log("contactListItem:path", props.path);

  const contact =
    useSelector((state) => {
      return state.Content?.data?.[props.path];
    });

  const DomainIcon =
    useDomainIcon(
      contact?.domain,
      contact?.indicators,
    );

  const setActiveDetail =
    useCallback((serviceId, props, show) =>
      dispatch(
        appActions
        .setActiveDetail(serviceId, props, show)),
      [dispatch],
    );

  if (!contact) {
    return null
  }

  const displayName =
    contact.name
    ?.substring(0, 20)
    .replaceAll('"', '') ||
    contact.email;

  return <List.Item
    key={props.path}
    className={`relative contacts-list-item ${isMobile ? 'is-mobile ' : ''}`}
    actions={[]}
    onClick={() =>
      setActiveDetail(
        "contacts", {
          path: props.path,
          contact,
        }, true)}
  >
    <List.Item.Meta
      avatar={
      <UserAvatar
        icon={DomainIcon}
        userId={props.path.split("/").pop()}
        name={contact.name}
        displayName={displayName}
        src={contact.photoURL}
      />}
      title={
        <div className="space space-center-y">
          <h6 className="capitalize">
            {displayName}
          </h6>
          <span key="ts" className="muted font-sm">
            <Day ts={contact.activityTs} />
          </span>
        </div>}
      description={<ExternalLink
        href={`https://${contact.domain}/`}
        target="_blank"
        className="muted ai-text lowercase"
      >
        {contact?.domain}
      </ExternalLink>}
    />
    <ListItemActive
      path={props.path}
      offset={'5px'}
    />
    {/*<div className="contacts-list-item-content" style={{width: '94%'}}>*/}
    {/*<div className="space space-col">*/}
        {/*<Button ghost><PhoneCallIcon size={18} /></Button>*/}
        {/*<div className={`space ${isMobile ? 'space-col ' : ''}`}>*/}
        {/*  <div className="space space-between">*/}
        {/*    <h6 className="capitalize"*/}
        {/*        onClick={() => setActiveDetail("contacts", {contactId: id, path, indexName}, true)}>*/}
        {/*      {contact.name?.substring(0, 30) || contact.email}*/}
        {/*    </h6>*/}
        {/*    <span className="muted font-sm uppercase">*/}
        {/*      <Day ts={contact.createdTs} format="MMM D"/>*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*  {isMobile && contactDomain}*/}
        {/*</div>*/}
        {/*{!isMobile && <Space>*/}
        {/*  {contact.isCustomer && <CustomerIcon className="muted-icon"/>}*/}
        {/*  <TimeAgo value={contact.createdTs} className="muted"/>*/}
        {/*</Space>}*/}
        {/*<div className="space space-between space-center-y"><ConnectedIntegrationsStrip /></div>*/}
        {/*{contact.attachmentIds?.length && <AttachmentsStrip attachmentIds={contact.attachmentIds}/>}*/}
        {/*{contact.domain === "microsoft.com" &&*/}
        {/*  <Space direction="vertical">*/}
        {/*    <div className="space space-center-y">*/}
        {/*      <PaymentIcon size={22} />*/}
        {/*      <LineConnector />*/}
        {/*      <Tag icon={<DollarCircleOutlined/>}>12.99</Tag>*/}
        {/*      <Tag icon={<CheckOutlined />} color="success">Paid</Tag>*/}
        {/*      <DownArrowOutlined size={16} />*/}
        {/*    </div>*/}
        {/*    <div className="space space-center-y">*/}
        {/*      <PaymentIcon size={22} style={{visibility: "hidden", verticalAlign: "middle"}} />*/}
        {/*      <LineConnector />*/}
        {/*      <Tag icon={<DollarCircleOutlined/>}>Automate</Tag>*/}
        {/*      <DownArrowOutlined size={16} />*/}
        {/*    </div>*/}
        {/*  </Space>*/}
        {/*}*/}
        {/*{contact.domain === "gmail.com" && <div className="space space-center-y">*/}
        {/*  <GoogleIcon size={16} />*/}
        {/*  <LineConnector />*/}
        {/*  <TimeAgo value={contact.createdTs + 420000000} />*/}
        {/*  <DownArrowOutlined size={16} />*/}
        {/*</div>*/}
        {/*}*/}
        {/*{contact.domain === "adeenidesigngroup.com" && <div className="space space-center-y">*/}
        {/*  <LayersIcon className="gold-icon" />*/}
        {/*  <LineConnector style={{borderTopColor: 'goldenrod'}} />*/}
        {/*  <Tag icon={<PlusOutlined/>} color="gold">2 Automations</Tag>*/}
        {/*  <DownArrowOutlined size={16} />*/}
        {/*  </div>*/}
        {/*}*/}
      {/*</div>*/}
  </List.Item>
}

export default ContactsListItem;

// actions={!isMobile ? [
// <div className="space">
//   <Button ghost className="icon-btn icon-right">
//     <EmailIconOutlined size={22} className="muted-icon" />
//   </Button>
//   <Button ghost className="icon-btn icon-right">
//     <PhoneCallIcon size={20} className="muted-icon" />
//   </Button>
//   <Button ghost className="icon-btn icon-right">
//     <HeartIcon size={20} className="muted-icon" />
//   </Button>
//   {/*<DevButton path={path} />*/}
// </div>
// ] : []}
