import React from "react";
import PropTypes from "prop-types";
import MessagesListItem from "./MessagesListItem";
import {List, Typography} from "antd";
import MessageCard from "./MessageCard";


EmailsCard.propTypes = {
  messages: PropTypes.array,
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  title: PropTypes.string,
  style: PropTypes.object,
  card: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

function EmailsCard({messages = [], loading, pageSize = 1, title, style, card, className, onClick}) {
  const getListItem = ([messageId, message, path]) => {
    return !!card ?
      <List.Item key={path}>
        <MessageCard path={path} message={message} className={className} onClick={onClick} />
      </List.Item> :
      <MessagesListItem key={path} messageId={messageId} message={message} path={path} onClick={onClick} />
  }
  return <List
  className="scribe-widget"
  style={{width: '100%', maxWidth: '600px', ...style}}
  header={!!title && <Typography.Title level={4}>{title}</Typography.Title>}
  dataSource={messages}
  renderItem={getListItem}
  loading={loading}
  locale={{emptyText: "No messages found"}}
  pagination={{pageSize, hideOnSinglePage: true}}
  />
}

export default EmailsCard;
