import {useDispatch, useSelector} from "react-redux";
import {List} from "antd";
import React, {useCallback} from "react";
import appActions from "@web/redux/app/actions";
import Day from "./Day";
// import {RightArrowOutlined, UserTodoIcon} from "@web/assets/icons";
import AttachmentsStrip from "./AttachmentsStrip";
import ListItemActive from "./ListItemActive";
import UserAvatar from "./UserAvatar";
// import ExternalLink from "./ExternalLink";
// import dayjs from "dayjs";
import useDomainIcon from "@web/hooks/useDomainIcon";

export const ThreadsListItem = ({list, ...props}) => {
  const dispatch =
    useDispatch();

  /** @type {models.EmailThread.Data} */
  const item =
    useSelector((state) =>
      state.Content.data?.[props.path]);

  const itemIsMessage =
    !item?.latest?.message;

  const setActiveDetail =
    useCallback((serviceId, props, show) =>
      dispatch(
        appActions
        .setActiveDetail(
          serviceId,
          props,
          show,
        )),
    [dispatch]);

  const isCleanMode =
    useSelector((state) =>
      state.App.modeClean);

  const messagePath =
    props.path.includes("/messages/") ?
      props.path :
      item?.latest?.message?.path;

  const message =
    itemIsMessage ?
      item :
      item?.latest?.message;

  const contact =
    useSelector((state) =>
      message?.memberUserIds?.length &&
      state.Content.members?.[message?.memberUserIds[0]]);

  const DomainIcon =
    useDomainIcon(
      contact?.domain,
      contact?.indicators,
    );

  const isPromo =
    item?.indicators?.isPromo;

  if (isPromo && isCleanMode) {
    return null;
  }

  return <List.Item
    className={`messages-list-item ${isPromo ? 'isPromo' : ''} is-mobile`}
    key={props.path}
    onClick={() =>
      setActiveDetail(
        "threads",
        {
          path: messagePath,
          message,
          thread: itemIsMessage ?
            null :
            item,
        },
        true)
    }
  >
    <List.Item.Meta
      avatar={
        <UserAvatar
          icon={DomainIcon}
          userId={props.path.split("/").pop()}
          name={message.from.name}
          displayName={message.from.name || message.from.email}
          src={contact?.photoURL}
        />}
      title={
        <div className="space space-center-y">
          <span className="line-clamp-1">
            {message?.from?.name &&
              message.from.name
              .substring(0, 25)
              .replaceAll('"', '')}
          </span>
          <span key="ts" className="muted font-sm">
            <Day ts={message?.date} />
          </span>
        </div>}
      description={
        <React.Fragment>
          <span
            className={`${isPromo ? 'muted' : 'text-color'} line-clamp-1`}
            style={{fontWeight: 600}}
          >
            {message
            ?.subject
            ?.substring(0, 40)}
          </span>
          <span
            className={`line-clamp-2${isPromo ? ' muted' : ' ai-text'}`}
            style={{lineHeight: 1.4}}
          >
            {message
            ?.snippet
            ?.substring(0, 62)}
          </span>
          {!!Object
            .keys(message?.attachments || {})
              .length &&
            <AttachmentsStrip
              style={{marginTop: 10}}
              list={Object.values(message.attachments)}
            />
          }
        </React.Fragment>
      }
    />
    <ListItemActive path={messagePath} offset={'3px'} />
    {/*<div*/}
    {/*  className="space space-col messages-list-item-content"*/}
    {/*  onClick={() =>*/}
    {/*    setActiveDetail(*/}
    {/*      "threads",*/}
    {/*      {*/}
    {/*        path: messagePath,*/}
    {/*        message,*/}
    {/*        thread: itemIsMessage ?*/}
    {/*          null :*/}
    {/*          item,*/}
    {/*      },*/}
    {/*      true)*/}
    {/*  }*/}
    {/*>*/}
    {/*  <ListItemActive path={messagePath} offset={'-20px'} />*/}
    {/*  <div className="space space-center-y space-between">*/}
    {/*    <div className="space space-center-y">*/}
    {/*      /!*<RightArrowOutlined*!/*/}
    {/*      /!*  size={16}*!/*/}
    {/*      /!*  className="muted-icon"*!/*/}
    {/*      <span*/}
    {/*        className="no-wrap messages-list-item-from"*/}
    {/*        style={{fontWeight: 400}}*/}
    {/*      >*/}
    {/*        {message?.from?.name &&*/}
    {/*          message.from.name.replaceAll('"', '')}*/}
    {/*      </span>*/}
    {/*    </div>*/}
    {/*    <span className="font-sm muted">*/}
    {/*      <Day*/}
    {/*        ts={message?.date}*/}
    {/*        format="MMM D h:mma"*/}
    {/*      />*/}
    {/*    </span>*/}
    {/*  </div>*/}
    {/*  <span*/}
    {/*    className={`${isPromo ? 'muted' : ''} line-clamp-1`}*/}
    {/*    style={{fontWeight: 800}}*/}
    {/*  >*/}
    {/*    {message*/}
    {/*    ?.subject*/}
    {/*    ?.substring(0, 40)}*/}
    {/*  </span>*/}
    {/*  <span*/}
    {/*    className="ai-text line-clamp-2"*/}
    {/*    style={{lineHeight: 1.4}}*/}
    {/*  >*/}
    {/*    {message*/}
    {/*    ?.snippet*/}
    {/*    ?.substring(0, 72)}*/}
    {/*  </span>*/}
    {/*  {!!Object*/}
    {/*    .keys(message?.attachments || {})*/}
    {/*    .length &&*/}
    {/*    <AttachmentsStrip*/}
    {/*      style={{marginTop: 10}}*/}
    {/*      list={Object.values(message.attachments)}*/}
    {/*    />*/}
    {/*  }*/}
    {/*</div>*/}
  </List.Item>
}
