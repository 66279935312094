import React from 'react';
import PropTypes from 'prop-types';
import TimelineActivityEvent from "./TimelineActivityEvent";
import {Card, Typography} from "antd";
import {DownArrowOutlined, PlusIcon} from "@web/assets/icons";
import {Link} from "wouter";
import SlideCardWrapper from "./SlideCardWrapper";
import {useSelector} from "react-redux";
import Toggle from "./Toggle";
import LineConnector from "./LineConnector";

/**
 * @typedef {Object} web.client.ContentItemActivity
 * @property {string} activityPath
 * @property {omit<models.Activity.ActivityEvent, "recommendedTodo", "reminder", "update">} event
 * @property {string} title
 * @property {string} goal
 * @property {models.Activity.ActivityEventRecommendedTodo} [recommendedTodo]
 * @property {reminder: models.Activity.ActivityEventReminder} [reminder]
 * @property {number} date
 * @property {string} serviceId
 */
//
// /**
//  * @function getContentItemActivity
//  * @param {models.Activity} activity
//  * @param {string} activityPath
//  * @param {string} serviceId
//  * @returns {web.client.ContentItemActivity}
//  */
// const getContentItemActivity = (activity, activityPath, serviceId) => {
//   /** @type {web.client.ContentItemActivity} */
//   const contentItemActivity = {};
//   contentItemActivity.serviceId = serviceId;
//   contentItemActivity.activityPath = activityPath;
//
//   const eventId =
//     [...activity
//       .eventIds ||
//     [],
//     ].pop();
//
//   /** @type {models.Activity.ActivityEvent} */
//   const activityEvent =
//     activity
//       .events?.[eventId];
//
//   const {
//     recommendedTodo,
//     update,
//     ...restOfEvent
//   } = activityEvent || {};
//
//   activityEvent &&
//   (contentItemActivity.event = restOfEvent);
//
//   recommendedTodo &&
//   (contentItemActivity.recommendedTodo = recommendedTodo);
//
//   if (activityEvent.update) {
//     typeof activityEvent
//       .update.activitySummary === 'boolean' &&
//     (contentItemActivity.event.activitySummary =
//       activityEvent
//         .update
//         .activitySummary);
//
//     typeof activityEvent
//       .update.activityRequiresAction === 'boolean' &&
//     (contentItemActivity.event.activityRequiresAction =
//       activityEvent
//         .update
//         .activityRequiresAction);
//
//     const {
//       recommendedTodo: recommendedTodoUpdates,
//       reminder,
//     } = activityEvent
//       .update;
//
//     activityEvent
//       .update
//       .recommendedTodo &&
//     (contentItemActivity.recommendedTodo = {
//       ...contentItemActivity.recommendedTodo || null,
//       ...recommendedTodoUpdates||null});
//
//     activityEvent
//       .update
//       .reminder &&
//     (contentItemActivity.reminder =
//       activityEvent
//         .update
//         .reminder);
//   }
//   contentItemActivity.title =
//     contentItemActivity.recommendedTodo?.todoStatus !== "completed" ?
//       contentItemActivity.recommendedTodo?.summary ||
//       contentItemActivity.event?.activitySummary :
//       contentItemActivity.event?.activitySummary;
//
//   contentItemActivity.goal =
//     contentItemActivity.recommendedTodo?.goal;
//
//   return contentItemActivity;
// }

TimelineItemEventsList.propTypes = {
  contextPath: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  serviceId: PropTypes.string.isRequired,
  cardTitle: PropTypes.string,
  showDetails: PropTypes.bool,
  children: PropTypes.node,
};

function TimelineItemEventsList(props) {
  const [expanded, setExpanded] = React
  .useState(props.showDetails);

  React.useEffect(() => {
    setExpanded(props.showDetails);
  }, [props.showDetails]);

  const eventIdList =
    useSelector((state) => {
      return state.Content.activity?.[props.contextPath]?.eventsByItem?.[props.path];
    });

  if (!eventIdList?.length) {
    return props.children;
  }

  const onClick =
    React
    .useCallback(() => {
      setExpanded(!expanded);
      }, [expanded]);

  return <React.Fragment>
    {eventIdList
    .map((eventId) =>
      <TimelineActivityEvent
        key={eventId}
        eventId={eventId}
        // activityEvent={activityEvent}
        // date={activityEvent.date}
        cardTitle={props.cardTitle}
        onClick={onClick}
      />
    )}
    {expanded &&
      props.children}
    {/*<Toggle*/}
    {/*  trigger={*/}
    {/*  <div*/}
    {/*    style={{marginLeft: '60px'}}*/}
    {/*    className="space space-center-y"*/}
    {/*  >*/}
    {/*    Details <LineConnector /><DownArrowOutlined />*/}
    {/*  </div>*/}
    {/*  }*/}
    {/*>*/}
    {/*  {props.children}*/}
    {/*</Toggle>*/}
  </React.Fragment>;
}

export default TimelineItemEventsList;
