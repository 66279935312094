import React from 'react';
import PropTypes from 'prop-types';
import {List, Tag, Typography} from "antd";
import UserAvatar from "./UserAvatar";
import {
  AttachmentIcon,
  CalendarIcon,
  EmailIconOutlined,
  FlagIcon,
  LayersIcon,
  PaymentIcon,
  TruckIcon
} from "@web/assets/icons";
import now from "lodash/now";
import Day from "./Day";
import MarkdownTimeline from "./MarkdownTimeline";
import Toggle from "./Toggle";
import StatusActions from "./StatusActions";
import {useSelector} from "react-redux";
import {api} from "@web/lib/api";
import RecommendedTodo from "./RecommendedTodo";
import ActivityIconStrip from "./ActivityIconStrip";

ContactDetailsListItemMeta.propTypes = {
  path: PropTypes.string.isRequired,
  activityPath: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactIcon: PropTypes.node,
};

function ContactDetailsListItemMeta(props) {
  const event =
    useSelector((state) =>
      state.Content.events[props.eventId]);

  let memberExtraCount = 0;
  const memberNames = Object
  .values(event?.members || {})
  .filter(({displayName}) =>
    displayName !== props.name)
  .map(({displayName}, index) => {
    if (index < 3) {
      return <span key={displayName}>{!!index && ", "}{displayName}</span>;
    }
    memberExtraCount++;
    return null;
  });


  return <List.Item.Meta
    avatar={
    <UserAvatar
      userId={props.path.split("/").pop()}
      path={props.path}
      displayName={props.displayName}
      email={props.email}
      icon={event.isUrgent ?
        <FlagIcon
          key="urgent"
          style={(event?.activityTs > (now() - (1000 * 60 * 60 * 48))) ? {color: "orange"} : null}
          size={18}
        /> :
        props.contactIcon
      }
      extra={
      event?.isUrgent &&
        <Tag color="orange">Urgent</Tag>
      }
    />
    }
    title={
      <div className="space space-center-y">
        <Typography.Title level={5}>
          {memberNames}
          {!!memberExtraCount &&
            <span className="muted"> +{memberExtraCount}</span>}
        </Typography.Title>
        <ActivityIconStrip eventId={props.eventId} />
        <span className="font-sm muted">
          <Day ts={event?.date} />
        </span>
      </div>}
    description={
      <MarkdownTimeline
        markdown={event?.summary}
        action={[
          // <Button onClick={onClickItem} ghost className="icon-btn icon-left"><DownArrowOutlined /> Details</Button>,
          <RecommendedTodo
            key={props.eventId}
            eventId={props.eventId}
            activityPath={props.activityPath}
            collapsed
          />,
        ]}
      />
    }>
  </List.Item.Meta>
}

export default ContactDetailsListItemMeta;
