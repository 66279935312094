import React from "react";
import PropTypes from "prop-types";
import useDocumentFirestore from "@web/hooks/useDocumentFirestore";
import DraftEditor from "./DraftEditor";
import {deletePath, setPath, updatePath} from "@web/lib/firestore.db";
import now from "lodash/now";
import {api} from "@web/lib/api";
import {notification} from "antd";
import aiActions from "@web/redux/ai/actions";
import {useDispatch} from "react-redux";
import useChatStreamListener from "@web/hooks/useChatStreamListener";
import {chatAppAgents} from "@oven/app-thescribe/src/dashboard.config";
import useFunctionsCallable from "@web/hooks/useFunctionsCallable";

DraftEditorManager.propTypes = {
  agentId: PropTypes.string,
  draftPath: PropTypes.string,
  collapsed: PropTypes.bool,
  onAddContextPath: PropTypes.func,
  onRemoveContextPath: PropTypes.func,
};

function DraftEditorManager(props) {
  const {draftPath} = props;

  const dispatch =
    useDispatch();

  const [chat, chatPath] =
    useChatStreamListener(props.agentId);

  const [isPending, startTransition] = React
  .useTransition();

  const [sending, setSending] =
    React.useState(false);

  const [sendError, setSendError] =
    React.useState(null);

  const [finished, setFinished] =
    React.useState(false);

  const [draft, loadingDraft, error, snap] =
    useDocumentFirestore(draftPath);

  const [parentEmailMessage] =
    useDocumentFirestore(draft?.parentEmailPath);

  const [text, setText] = React
  .useState(draft?.message || "");

  const [collapsed, setCollapsed] = React
  .useState(props.collapsed);

  const addEmail = React
  .useCallback((email) => {
    console.log("addEmail:", email);
    const alreadyInDistro = draft
    .toEmails
    .some((member) =>
      member.email === email);

    if (alreadyInDistro) {
      return;
    }

    return updatePath(draftPath,{
      ...draft,
      toEmails: [
        ...draft.toEmails,
        {email, name: email},
      ],
    });
  }, [draft]);

  const updateSendAction = React
  .useCallback((newAction) => {
    console.log("updateSendAction:", newAction);
    return updatePath(draftPath, {
      ...draft,
      action: newAction,
    });
  }, [draft]);

  const save = React
  .useCallback(() => {
    console.log("Saving draft", draftPath);
    return setPath(
      props.draftPath, {
        ...draft,
        message: text,
        updatedTs: now(),
      });
  }, [draft]);

  const send = React
  .useCallback(() => {
    console.log("Sending email", draftPath);
      setSending(true);
      return setPath(
        props.draftPath, {
          ...draft,
          message: text,
          updatedTs: now(),
          submitTs: now(),
        },
      )
      .then(() =>
        api.POST(
          "http://127.0.0.1:5001/admin-85663/us-west1/user-sendEmail",
          {path: draftPath}),
      )
      .then((res) => {
        console.log("Response", res);
        if (res.error) {
          setSending(false);
          setSendError(res);
          return;
        }
        notification
        .success({
          message: "Email sent",
          description: "Your email has been sent successfully",
        });
        setFinished(true);

        aiActions
        .clearActiveEmailDraft();
      })
      .catch((err) => {
        setSending(false);
        notification
        .error({
          message: "An error occurred",
          description: "We are looking into it",
        });
      });
  },[draft, draftPath]);

  const collapse = React
  .useCallback(() => {
    console.log("Collapsed:", collapsed);
      setCollapsed(!collapsed);
    }, [collapsed]);

  const deleteDraft = React
  .useCallback(() => {
    return deletePath(draftPath);
  }, [draftPath]);

  const onTextChange = React
  .useCallback((message) => {
    console.log("onTextChange", message);
    setText(message);
  }, []);

  const getDistroLists = React
  .useCallback(() => {
    // console.log("getDistroLists");
    if (!draft) {
      return [[], []];
    }
    switch (draft.action) {
      case "reply": {
        const to =
          parentEmailMessage?.replyTo ||
          parentEmailMessage?.from;

        return [
          [to]
          .filter(Boolean),
          [],
        ];
      }
      case "reply-all": {
        const to =
          parentEmailMessage?.replyTo ||
          parentEmailMessage?.from;

        return [
          [to]
          .filter(Boolean),
          ...(parentEmailMessage?.to || [])
          .filter(({email}) =>
            email !== to.email),
        ];
      }
      default: {
        return [[], []];
      }
    }
  }, [draft, parentEmailMessage]);

  React
  .useEffect(() => {
    console.log("DraftEditorManager:useEffect:draft:", draft?.message);
    !!draft &&
    setText(draft?.message || "");
  }, [draft?.message]);

  React
  .useEffect(() => {
    finished ?
      props.onRemoveContextPath(draftPath) :
      props.onAddContextPath(draftPath);
  }, [finished]);

  React
  .useEffect(() => {
    console.log("DraftEditorManager:useEffect:chat:", chat);
    if (!chat) {
      return;
    }
    const draftAnswer = chat
    .answers
    .find((answer) =>
      chatAppAgents.emailWriter.agentIds.includes(answer.agentId));

    draftAnswer && // todo: Use startTransition() for performance if needed
    setText(draftAnswer.text);
  }, [chat, chatPath]);

  React
  .useEffect(() => {
    !loadingDraft &&
    !draft &&
    setFinished(true);
  }, [loadingDraft, draft]);

  React.useEffect(() => {
    finished &&
    dispatch(
      aiActions
      .clearActiveEmailDraft(),
    );
  }, [finished]);

  // toEmails: props.draft.toEmails || null,
  // ccEmails: ccEmails || initialDraft?.ccEmails || null,
  // subject: formatSubject(subject),
  // message,
  // attachments: attachments || initialDraft?.attachments || null,
  // parentEmailMessagePath: (props.parentEmailMessagePath && props.sendAction === 'reply') ?
  //   props.sendAction
  //   .split("/")
  //   .pop() :
  //   null,
  // originThreadId: (props.threadPath && action === 'reply') ?
  //   props.threadPath
  //   .split("/")
  //   .pop() :
  //   null,
  console.log("DraftEditorManager:draft:", {finished});

  if (loadingDraft) {
    return null;
  }

  return <DraftEditor
    collapsed={!!props.collapsed}
    draft={draft}
    parentEmailMessage={parentEmailMessage}
    sending={sending}
    finished={finished}
    onAddEmail={addEmail}
    onClickSave={save}
    onClickSend={send}
    onClickCollapse={collapse}
    onClickDelete={deleteDraft}
    onTextChange={onTextChange}
    onChangeSendAction={updateSendAction}
    getDistroLists={getDistroLists}
    text={text}
  />;

  // const executeAction = () => {
  //   setFinished(true);
  //   return api.POST("http://127.0.0.1:5001/admin-85663/us-west1/user-sendEmail", {
  //     action: draft.action,
  //     path: draftPath,
  //     date: now(),
  //   }).then((res) => {
  //     console.log("Response", res);
  //     if (res.error) {
  //       setFinished(false);
  //       setSendError(res);
  //       return;
  //     }
  //     notification.success({
  //       message: "Email sent",
  //       description: "Your email has been sent successfully",
  //     });
  //   }).catch((err) => {
  //     console.error("Error", err);
  //   });
  // };
  // const cancelAction = () => {
  //   setFinished(true);
  //   return setPath(
  //     [draftPath, "user-actions", "cancel"].join("/"),
  //     {date: now()},
  //   );
  // };
  // const onFocus = () => null;
  // return <Card
  //   hoverable={finished}
  //   bordered={false}
  //   // title={<Input
  //   //   bordered={false}
  //   //   size="large"
  //   //   value={draft?.subject}
  //   //   addonBefore="Subject:"
  //   // />}
  //   className="card-rounded card-simple extra-round card-no-header email-editor-card"
  //   bodyStyle={{padding: "0"}}
  //   // extra={<Button size="small" ghost className="icon-btn" onClick={cancelEmail}><XIcon size={18} className="muted-icon" /></Button>}
  //   actions={[
  //     // <Button>Permission</Button>,
  //     // <Button onClick={executeAction} className="icon-btn icon-left"><GMailIcon size={20} />{actionLabel || action}</Button>,
  //   ]}
  //   style={props.style}
  // >
  //   {/*<Input*/}
  //   {/*  bordered={false}*/}
  //   {/*  size="large"*/}
  //   {/*  value={draft?.subject}*/}
  //   {/*  addonBefore="Subject"*/}
  //   {/*  className="email-editor-textarea mb-6 ai-text"*/}
  //   {/*/>*/}
  //   <Input.TextArea
  //     disabled={finished}
  //     className="email-editor-textarea brainstorm-editor"
  //     value={draft.message}
  //     placeholder="Type your email here..."
  //     autoSize={{minRows: 3, maxRows: 14}}
  //     size="large"
  //     // onTextChange={(message) => updateDraft({message})}
  //     onFocus={onFocus}
  //     onPressEnter={onFocus}
  //   />
  //   <div className="space space-center-y space-between p-6 widget-action-strip">
  //     <div>
  //       <Button ghost onClick={cancelAction} size="large"><span className="green-color">Copy</span></Button>
  //     </div>
  //     <div>
  //       <GoogleAuthButton scopes={["https://www.googleapis.com/auth/gmail.send"]} size="large">Authorize Send</GoogleAuthButton>
  //       <Button
  //         disabled={finished}
  //         ghost onClick={executeAction} size="large"><span className="green-color">Send</span></Button>
  //     </div>
  //   </div>
  // </Card>;
  // setDistro([
  //   ...distro,
  //   {value: email, label: email},
  // ]);
  // setToEmails([...toEmails, {value: email, label: email}]);
}

export default DraftEditorManager;
