import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {Button, Card, Typography} from "antd";
import {AttachmentIcon, EmailIconOutlined} from "@web/assets/icons";
import appActions from "@web/redux/app/actions";
import {WindowContext} from "@web/hooks/context";
import UserAvatar from "./UserAvatar";
import useDomainIcon from "@web/hooks/useDomainIcon";

MessageCard.propTypes = {
  message: PropTypes.object,
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

function MessageCard(props) {
  const dispatch =
    useDispatch();

  const emailMessage =
    useSelector((state) =>
      state.Content.data?.[props.path]);

  const message =
    props.message || emailMessage;

  const contact = useSelector((state) => {
    return message?.memberUserIds?.length &&
      state.Content.members?.[message?.memberUserIds?.[0]];
  });

  const [isMobile] = React
  .useContext(WindowContext);

  const DomainIcon =
    useDomainIcon(
      contact?.domain,
      contact?.indicators,
    );

  const setActiveDetail =
    useCallback((serviceId, props, show) => {
    dispatch(appActions.setActiveDetail(serviceId, props, show));
  }, [dispatch]);

  const showDetail = (e) => {
    if (props.onClick) {
      props.onClick(e, {path, message});
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setActiveDetail("threads", {path, message}, true);
    return false;
  }

  if (!props.path) {
    return null;
  }

  if (!message) {
    return null;
  }

  return <Card
    title={<div className="space space-center-y" style={{maxWidth: '85%'}}><Button ghost><EmailIconOutlined /></Button><span className="line-clamp-1 muted">{message.subject}</span></div>}
    className={`card-rounded card-simple card-slim ant-card-body card-slim-title extra-round card-no-titles `}
    bordered={false}
    hoverable
    onClick={showDetail}
  >
    <div style={{position: 'relative'}} className="space space-col space-between">
      <div style={{
        maxHeight: '400px',
        overflow: isMobile ? "hidden" : "auto", paddingBottom: '10px'}}>
        <div style={{width: "100%"}} className="space space-col">
          <UserAvatar
            icon={DomainIcon}
            userId={props.path.split("/").pop()}
            name={message.from?.name}
            displayName={message.from?.name || message.from?.email}
            src={contact?.photoURL}
          />
          <Typography.Text className="font-xl"><span dangerouslySetInnerHTML={{__html: message.snippet || message.text || ""}}></span></Typography.Text>
          {message.attachments &&
            <div className="space space-center-y">
              <AttachmentIcon className="muted-icon" />
              <Typography.Text className="font-xl muted">{Object.keys(message.attachments).length}</Typography.Text>
            </div>}
        </div>
      </div>
    </div>
  </Card>
}

export default MessageCard;
